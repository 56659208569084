import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Table,
  Form,
  Input as Input1,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserCampaing.js";
import ExcelGenerator from "components/Excel/Excel.js";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sellersApi from "services/SellersApi";

import React from 'react';


const AlterGroup = () => {
  const query = new URLSearchParams(useLocation().search);
  var _id = query.get("id")

  const [group, setGroup] = useState([]);
  const [contacts, setContacts] = useState([]);


  const handleContacts = (values) => {
    var list_values = []
    values.forEach(function (elemento) {
      var numero = elemento.id.replace("@s.whatsapp.net", "")
      if (elemento.admin == null){
        elemento.admin = "user"
      }
      list_values.push({ numero: numero, tipo: elemento.admin })
    })
    setContacts(list_values)
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const response_con = await sellersApi.get(`/sellers-groups/${_id}`);
        setGroup(response_con.data)
        handleContacts(response_con.data.contacts_group)

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Grupo: {group.group_name}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Informações do Grupo
                </h6>
                <div className="pl-lg-4">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Numero</th>
                        <th scope="col">Tipo</th>
                      </tr>
                    </thead>
                    <tbody>
                      {contacts.map(contact => (
                        <tr>
                          <th>
                            {contact.numero}
                          </th>
                          <td>
                            {contact.tipo}
                          </td>
                        </tr>

                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className="text-center">
                  <ExcelGenerator data={contacts} fileName={group.group_name} />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default AlterGroup;
