import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useState, useEffect } from "react";
import sellersApi from "services/SellersApi"
import bcrypt from 'bcryptjs';

const Profile = () => {
  const username =  localStorage.getItem("username");
  const seller_id =  localStorage.getItem("seller_id");

  const [user, setUser] = useState([]);
  const [seller, setSeller] = useState([]);
  const [password, setPassword] = useState([]);
  const [connector, setConnector] = useState([]);
  const [connector_id, setConnectorId] = useState([]);

  const decryptText = (pass) => {
    //setPassword(CryptoJS.AES.decrypt(pass, 'secret key').toString(CryptoJS.enc.Utf8));
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    const user_name = e.target.elements.input_name.value;
    const user_email = e.target.elements.input_email.value;
    const user_username = e.target.elements.input_username.value
    const user_password = await bcrypt.hash(e.target.elements.input_password.value, 10);
    const payload_user = {
      username:user_username,
      password:user_password,
      email:user_email,
      name:user_name
    }
    try {
      const res = await sellersApi.patch(`sellers-users/${user.id}/`, payload_user)
    } catch (e) {
      alert(e)
    }

    const seller_name = e.target.elements.input_seller.value;
    const seller_phone = e.target.elements.input_telefone.value;
    const seller_cnpj = e.target.elements.input_cnpj.value;
    const seller_rua = e.target.elements.input_rua.value;
    const seller_cidade = e.target.elements.input_cidade.value;
    const seller_estado = e.target.elements.input_estado.value;
    const seller_pais = e.target.elements.input_pais.value;
    const seller_codigo_postal = e.target.elements.input_codigo_postal.value;
    const seller_numero = e.target.elements.input_numero.value;
    const seller_complemento = e.target.elements.input_complemento.value;
    
    const payload_seller = {
      city: seller_cidade,
      country: seller_pais,
      state: seller_estado,
      number: seller_numero,
      postal_code: seller_codigo_postal,
      complement: seller_complemento,
      street: seller_rua,
      name:seller_name,
      phone_number:seller_phone,
      cnpj:seller_cnpj
    }

    try {
      const res = await sellersApi.patch(`sellers/${seller.id}/`, payload_seller)
      
    } catch (e) {
      alert(e)
    }


    const connector_apikey = e.target.elements.input_evolution_api_key.value;
    const connector_api_url = e.target.elements.input_evolution_api_url.value;
    const connector_instance = e.target.elements.input_evolution_instance.value;
    const connector_global_api_key = e.target.elements.input_evolution_global_api_key.value;

    const payload_connector = {
      connect_information: {
        apikey: connector_apikey,
        api_url: connector_api_url,
        instance: connector_instance,
        global_api_key: connector_global_api_key,
      }
    }

    try {
      const res = await sellersApi.patch(`/sellers-connectors/${connector_id}/`, payload_connector)
      
    } catch (e) {
      alert(e)
    }


    alert("Alterado com sucesso!");
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response_user = await sellersApi.get(`/sellers-users/?username=${username}&seller_id=${seller_id}`);
        setUser(response_user.data[0]);
        setPassword(response_user.data[0].password);
        
        const response = await sellersApi.get(`/sellers/${seller_id}`);
        setSeller(response.data);

        const response_con = await sellersApi.get(`/sellers-connectors/?seller_id=${seller_id}`);
        setConnectorId(response_con.data[0].id)
        setConnector(response_con.data[0].connect_information);

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            
          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações de Usuario
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.name}
                            id="input_name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputemail"
                          >
                            Email
                          </label>
                          <Input
                            defaultValue={user.email}
                            className="form-control-alternative"
                            id="input_email"
                            placeholder="admin@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.username}
                            id="input_username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={password}
                            id="input_password"
                            placeholder="Password"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Informações da empresa
                  </h6>
                  <div className="pl-lg-4">
                  <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Empresa
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.name}
                            id="input_seller"
                            placeholder="Empresa teste"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Telefone
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.phone_number}
                            id="input_telefone"
                            placeholder="(11) 99999-9999"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            CNPJ
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.cnpj}
                            id="input_cnpj"
                            placeholder="00.000.000/0000-00"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Rua
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.street}
                            id="input_rua"
                            placeholder="Rua"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Cidade
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.city}
                            id="input_cidade"
                            placeholder="Cidade"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Estado
                          </label>
                          <Input
                          defaultValue={seller.state}
                            className="form-control-alternative"
                            id="input_estado"
                            placeholder="Estado"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Pais
                          </label>
                          <Input
                            defaultValue={seller.country}
                            className="form-control-alternative"
                            id="input_pais"
                            placeholder="Pais"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Codigo Postal
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.postal_code}
                            id="input_codigo_postal"
                            placeholder="00000-000"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Numero
                          </label>
                          <Input
                            defaultValue={seller.number}
                            className="form-control-alternative"
                            id="input_numero"
                            placeholder="000"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Complemento
                          </label>
                          <Input
                            defaultValue={seller.complement}
                            className="form-control-alternative"
                            id="input_complemento"
                            placeholder="Bloco 0 Ap 000"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Modulo Ativo - Evolution API</h6>
                  <div className="pl-lg-4">
                    
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            API Key
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={connector.apikey}
                            id="input_evolution_api_key"
                            placeholder=""
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            API URL
                          </label>
                          <Input
                            defaultValue={connector.api_url}
                            className="form-control-alternative"
                            id="input_evolution_api_url"
                            placeholder=""
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Instance
                          </label>
                          <Input
                            defaultValue={connector.instance}
                            className="form-control-alternative"
                            id="input_evolution_instance"
                            placeholder=""
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Global API Key
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={connector.global_api_key}
                            id="input_evolution_global_api_key"
                            placeholder=""
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    
                  </div>
                  <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Atualizar
                </Button>
              </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
