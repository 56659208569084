import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input as Input1,
  Container,
  Row,
  Col
} from "reactstrap";

import {
  StyleSheet,
  Image,
} from 'react-native';
// core components
import UserHeader from "components/Headers/UserCampaing.js";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import ScheduleApi from "services/ScheduleApi"
import sellersApi from "services/SellersApi";

import React from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button as Bott } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import video from "assets/img/icons/common/play.png"
import audio from "assets/img/icons/common/audio.png"
import documento from "assets/img/icons/common/documento.png"

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, groupName, theme) {
  return {
    fontWeight:
      groupName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const AlterCampaing = () => {
  const query = new URLSearchParams(useLocation().search);
  var _id = query.get("id")
  const seller_id = localStorage.getItem("seller_id");

  const [names, setNames] = useState([]);

  const setGroupNamesApi = (list_groups) => {
    var list_name = []
    list_groups.forEach(function (elemento) {
      list_name.push(elemento.group_name)
    })
    setNames(list_name)
  }

  const classes = useStyles();
  const theme = useTheme();
  const [groupName, setgroupName] = React.useState([]);

  const handleChange = (event) => {
    setgroupName(event.target.value);
  };
  const handleChangeGroup = (list_group_active) => {
    setgroupName(list_group_active);
  };

  const [status, setStatus] = useState(true);
  const [mark_all, setMarkAll] = useState();
  const [hour_notification, setHourHotification] = useState();
  const [campaing, setCampaing] = useState([]);
  const [start_day, setStart] = useState();
  const [end_day, setEnd] = useState();
  const [image, setImage] = useState('');
  const [imageAtual, setImageAtual] = useState('');
  const [checkboxes, setCheckboxes] = useState([
    { id: "Sunday", label: 'Sunday', checked: false },
    { id: "Monday", label: 'Monday', checked: false },
    { id: "Tuesday", label: 'Tuesday', checked: false },
    { id: "Wednesday", label: 'Wednesday', checked: false },
    { id: "Thursday", label: 'Thursday', checked: false },
    { id: "Friday", label: 'Friday', checked: false },
    { id: "Saturday", label: 'Saturday', checked: false },
  ]);

  const [respostas, setRespostas] = useState([]);
  const [fieldType, setFieldType] = useState('message');
  const [contador, setContador] = useState(4);
  const [selectedOption, setSelectedOption] = useState();

  const setDays = (days) => {
    setStart(days[0])
    setEnd(days[1])
  }

  const handleCheckboxChange = (id) => {
    setCheckboxes(checkboxes.map(checkbox =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    ));
  };

  const handleByApi = (list_days) => {
    list_days.forEach(function (elemento) {
      checkboxes.forEach(function (check) {
        if (check.id === elemento) {
          check.checked = true
        }
      })
    });
  }

  const setResp = (values) => {
    campaing.message = values.message
    var listResp = []
    var cont = 1
    values.respostas.forEach(function (elemento) {
      listResp.push({ id: "resp" + cont, label: 'Resposta', value: elemento })
      cont++
    })
    setRespostas(listResp);
  }

  const addField = () => {
    const novoItem = { id: "resp" + contador, label: 'Resposta', value: "" };
    setRespostas(prevLista => [...prevLista, novoItem]);
    setContador(contador + 1)
  }

  const setValueResp = (event) => {
    respostas.forEach(function (elemento) {
      if (elemento.id === event.id) {
        elemento.value = event.value
      }
    })
  };

  const removeResp = (event) => {
    setRespostas(prevLista => prevLista.filter((item, i) => i !== event));
  };


  const handleSelectChange = (event) => {
    if (event.target.value === "survey") {
      setFieldType(event.target.value);
    }
    else {
      setFieldType("message");
    }
  };

  // Método para lidar com a mudança de estado do checkbox "marcar todos"
  const handleCheckAllChange = (event) => {
    const checked = event.target.checked;
    setCheckboxes(checkboxes.map(checkbox => ({ ...checkbox, checked })));
  };

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const tittle = e.target.elements.input_titulo.value;
    var message = e.target.elements.input_message.value;
    const type_message = e.target.elements.type_message.value;
    const hour_notification = e.target.elements.hour_notification.value;
    const time_period_notification_start = e.target.elements.start_date.value;
    const time_period_notification_end = e.target.elements.end_date.value;

    var link_message = ""

    console.log(image)

    if (!image) {
      link_message = imageAtual
    }
    else {
      const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
      const REGION = process.env.REACT_APP_S3_REGION;

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      });

      const s3 = new S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: `${seller_id}/${image.name}`,
        Body: image,
        ContentDisposition: 'inline',
        ContentType: image.type,
      };

      try {
        const upload = await s3.putObject(params).promise();
        link_message = `https://imagens-front-end.s3.amazonaws.com/${seller_id}/${image.name}`

      } catch (error) {
        console.error(error);

        alert("Erro ao carregar imagem: " + error.message); // Inform user about the error
      }
    }

    var alter_days = []
    checkboxes.forEach(function (elemento) {
      if (elemento.checked === true) {
        alter_days.push(elemento.id)
      }
    });

    if (type_message === "survey") {
      var list_respostas = []
      respostas.forEach(function (elemento) {
        list_respostas.push(elemento.value)
      })

      message = JSON.stringify({
        "message": message,
        "respostas": list_respostas
      })
    }

    const payload = {
      tittle: tittle,
      message: message,
      type_message: type_message,
      hour_notification: hour_notification,
      days_notification: {
        week_days: alter_days
      },
      time_period_notification: {
        period_days: [time_period_notification_start, time_period_notification_end]
      },
      send_to: {
        groups: groupName
      },
      link_message: link_message,
      status: status,
      mark_all: mark_all,
      status_progress: "Created",
    }

    try {
      const res = await ScheduleApi.patch(`/schedules-config/${_id}/`, payload)
      alert("Alterado com sucesso!");
    } catch (e) {
      alert(e)
    }

  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response_con = await ScheduleApi.get(`/schedules-config/${_id}`);
        setCampaing(response_con.data)
        setDays(response_con.data.time_period_notification.period_days)
        handleByApi(response_con.data.days_notification.week_days)
        handleChangeGroup(response_con.data.send_to.groups)
        setStatus(response_con.data.status)
        setMarkAll(response_con.data.mark_all)
        setHourHotification(response_con.data.hour_notification)
        setSelectedOption(response_con.data.type_message)
        setImageAtual(response_con.data.link_message)

        if (response_con.data.type_message === "survey") {
          var values = JSON.parse(response_con.data.message)
          response_con.data.message = values.message
          setResp(values);
          setFieldType("survey")
        }
        setCampaing(response_con.data)
        const response = await sellersApi.get(`/sellers-groups/?seller_id=${seller_id}`);
        setGroupNamesApi(response.data);

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    setImage(e.files[0])

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 20000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 20 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    console.log(file.type)
    if (file.type.startsWith("video")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={video} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    if (file.type.startsWith("audio")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={audio} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    if (file.type.startsWith("application")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={documento} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    else {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    };
  }

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-4" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Inserir sua imagem aqui
        </span>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Campanha: {campaing.tittle}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações da empresa
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-titulo"
                          >
                            Titulo
                          </label>
                          <Input1
                            className="form-control-alternative"
                            defaultValue={campaing.tittle}
                            id="input_titulo"
                            placeholder="Titulo"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {fieldType === 'message' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Mensagem
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Mensagem"
                              rows="4"
                              id="input_message"
                              defaultValue={campaing.message}
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Titulo da enquete
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Titulo da enquete"
                              id="input_message"
                              defaultValue={campaing.message}
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <div className="pl-lg-4" id="survey_resp">
                          {respostas.map((id, index) => (
                            <Col md="12">
                              <FormGroup>
                                <Input1
                                  className="form-control-alternative"
                                  placeholder="Resposta"
                                  id={id.id}
                                  defaultValue={id.value}
                                  type="text"
                                  onChange={(e) => setValueResp(e.target)}
                                />
                                <Button id={id.id} className="my-4" color="danger" type="button" onClick={(e) => removeResp(index)}>
                                  Remover Resposta
                                </Button>
                              </FormGroup>
                            </Col>
                          ))}
                        </div>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="button" onClick={addField}>
                          Adicionar mais respostas
                        </Button>
                      </div>
                    )}

                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-type-message"
                        >
                          Tipo de Mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>
                            <select defaultValue={selectedOption} className="form-control-alternative" id="type_message" onChange={handleSelectChange}>
                              <option value={selectedOption}>{selectedOption}</option>
                              <option value="default">Default</option>
                              <option value="audio">Audio</option>
                              <option value="video">Video</option>
                              <option value="photo">Foto</option>
                              <option value="document">Documento</option>
                              <option value="survey">Enquete</option>
                            </select>
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-link"
                        >
                          Link da Foto, Audio e Video
                        </label >
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Card className="shadow">
                            <Toast ref={toast}></Toast>
                            <Tooltip target=".custom-choose-btn" content="Selecionar Documento" position="bottom" />
                            <Tooltip target=".custom-cancel-btn" content="Apagar" position="bottom" />
                            <FileUpload ref={fileUploadRef} name="demo[]" multiple accept="image/*" maxFileSize={10000000}
                              onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                              headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                              chooseOptions={chooseOptions} cancelOptions={cancelOptions} />
                            {selectedOption != 'default' && (
                              <label className="justify-content-center">
                                <a href={imageAtual}>
                                  <h3>Clique aqui para ver o documento atual!</h3>
                                </a>
                              </label>
                            )}
                          </Card>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-time"
                        >
                          Horario do envio da mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="time"
                            id="hour_notification"
                            onChange={ev => setHourHotification(ev.target.value)}
                            step="600"
                            value={hour_notification}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Periodo
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="date"
                            id="start_date"
                            onChange={ev => setStart(ev.target.value)}
                            value={start_day}
                          />
                          <input
                            type="date"
                            id="end_date"
                            onChange={ev => setEnd(ev.target.value)}
                            value={end_day}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-check"
                        >
                          Dias para envio da mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label >
                            <input
                              type="checkbox"
                              checked={checkboxes.every(checkbox => checkbox.checked)}
                              onChange={handleCheckAllChange}
                            />
                            Marcar Todos
                          </label>
                          {checkboxes.map(checkbox => (
                            <label>
                              <input
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                              />
                              {checkbox.label}
                            </label>
                          ))}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Grupos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="imput_select_groups"
                              multiple
                              value={groupName}
                              onChange={handleChange}
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name} style={getStyles(name, groupName, theme)}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Col>
                      </Row>

                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-check"
                          >
                            Marcar todos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label >
                              <input
                                type="checkbox"
                                id="mark_all"
                                checked={mark_all} onChange={() => setMarkAll(!mark_all)}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-check"
                          >
                            Status
                          </label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label >
                              <input
                                type="checkbox"
                                id="status"
                                checked={status} onChange={() => setStatus(!status)}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Atualizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const styles = StyleSheet.create({
  stretch: {
    width: 150,
    height: 150,
    resizeMode: 'stretch',
  },
});

export default AlterCampaing;
