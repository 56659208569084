import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";

import sellersApi from "services/SellersApi"
import Header from "components/Headers/Header.js";
import React, { useState, useEffect } from "react";

const Contacts = () => {
  const seller_id =  localStorage.getItem("seller_id");

  const [seller_contacts, setSellerContacts] = useState([]);


  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await sellersApi.get(`/sellers-contacts/?seller_id=${seller_id}`);
        setSellerContacts(response.data);

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Contatos</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Numero</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                { seller_contacts.map( seller_contact => (
                  <tr>
                    <th scope="row">
                      <span className="mb-0 text-sm">
                        {seller_contact.name}
                      </span>
                    </th>
                    <td>{seller_contact.phone_number}</td>
                    <td className="text-right">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          className="btn-icon-only text-light"
                          href={seller_contact.id}
                          role="button"
                          size="sm"
                          color=""
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fas fa-ellipsis-v" />
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-arrow" right>
                          <DropdownItem
                            href={seller_contact.id}
                            onClick={(e) => e.preventDefault()}
                          >
                            Exluir
                          </DropdownItem>
                          <DropdownItem
                            href={seller_contact.id}
                            onClick={(e) => e.preventDefault()}
                          >
                            Editar
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                
                  ))} 
                </tbody>
              </Table>
              
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Contacts;
