import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input as Input1,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserCampaing.js";
import sellersApi from "services/SellersApi";
import { useState, React } from "react";


function CreateGroup() {
  const seller_id = localStorage.getItem("seller_id");

  const [status, setStatus] = useState(true);

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const group_name = e.target.elements.group_name.value;
    const description = e.target.elements.description_group.value;
    var contacts_group = e.target.elements.contacts_group.value.split(";");

    var list_values = []
    contacts_group.forEach(function (elemento) {
      list_values.push({ id: elemento, admin: null })
    })

    const payload = {
      group_id: "0",
      group_name: group_name,
      contacts_group: list_values,
      seller_id: seller_id,
      description:description,
      restrict:status
    }

    console.log(payload)
    try {
      const res = await sellersApi.post(`/sellers-groups/`, payload)
      alert("Criado com sucesso!");
    } catch (e) {
      alert(e)
    }
  };


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Criar Grupo</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações do Grupo
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-titulo"
                          >
                            Nome do Grupo
                          </label>
                          <Input1
                            className="form-control-alternative"
                            id="group_name"
                            placeholder="Nome do Grupo"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-message"
                          >
                            Descrição
                          </label>
                          <Input1
                            className="form-control-alternative"
                            placeholder="Contatos"
                            rows="4"
                            id="description_group"
                            type="textarea"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-message"
                          >
                            Apenas administradores podem mandar mensagem
                          </label>
                          <br></br>
                          <label>
                              <input
                                type="checkbox"
                                checked={status} onChange={() => setStatus(!status)}
                              />
                            </label>
                          
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-message"
                          >
                            Contatos
                          </label>
                          <Input1
                            className="form-control-alternative"
                            placeholder="Contatos"
                            rows="10"
                            id="contacts_group"
                            type="textarea"
                          />
                          <p>OBS: os contatos devem ser colocados separados por ";"
                            ex.: 55111234123456;552245674567;... </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    

                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Criar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container >
    </>
  );
};

export default CreateGroup;
