import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
  } from "reactstrap";
  // core components
  import UserHeader from "components/Headers/UserHeader.js";
  import { useState, useEffect } from "react";
  import sellersApi from "services/SellersApi"
  
  const Connectors = () => {
    const seller_id =  localStorage.getItem("seller_id");
  
    const [connector, setConnector] = useState([]);
    const [connector_id, setConnectorId] = useState([]);
  
    const handleSubmitEvent = async (e) => {
      e.preventDefault();
  
      const connector_apikey = e.target.elements.input_evolution_api_key.value;
      const connector_api_url = e.target.elements.input_evolution_api_url.value;
      const connector_instance = e.target.elements.input_evolution_instance.value;
      const connector_global_api_key = e.target.elements.input_evolution_global_api_key.value;
  
      const payload_connector = {
        connect_information: {
          apikey: connector_apikey,
          api_url: connector_api_url,
          instance: connector_instance,
          global_api_key: connector_global_api_key,
        }
      }
  
      try {
        const res = await sellersApi.patch(`/sellers-connectors/${connector_id}/`, payload_connector)

        
      } catch (e) {
        alert(e)
      }
  
  
      alert("Alterado com sucesso!");
    };
  
    useEffect(() => {
      const fetch = async () => {
        try {
          
          const response_con = await sellersApi.get(`/sellers-connectors/?seller_id=${seller_id}`);
          setConnectorId(response_con.data[0].id)
          setConnector(response_con.data[0].connect_information);

  
        } catch (err) {
          console.error(err);
        }
      };
      fetch();
    }, []);
  
  
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
              
            </Col>
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Minha conta</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={handleSubmitEvent}>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">Modulo Ativo - Evolution API</h6>
                    <div className="pl-lg-4">
                      
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              API Key
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={connector.apikey}
                              id="input_evolution_api_key"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              API URL
                            </label>
                            <Input
                              defaultValue={connector.api_url}
                              className="form-control-alternative"
                              id="input_evolution_api_url"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Instance
                            </label>
                            <Input
                              defaultValue={connector.instance}
                              className="form-control-alternative"
                              id="input_evolution_instance"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
  
                      <Row>
                        
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Global API Key
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue={connector.global_api_key}
                              id="input_evolution_global_api_key"
                              placeholder=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      
                    </div>
                    <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Atualizar
                  </Button>
                </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };
  
  export default Connectors;
  