import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input as Input1,
  Container,
  Row,
  Col
} from "reactstrap";


// core components
import UserHeader from "components/Headers/UserCampaing.js";
import { useState, useEffect, useRef } from "react";
import ScheduleApi from "services/ScheduleApi"
import sellersApi from "services/SellersApi";
import { Link } from "react-router-dom";
import React from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button as Bott } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeicons/primeicons.css';
import SelectReact from "react-select";
import video from "assets/img/icons/common/play.png"
import audio from "assets/img/icons/common/audio.png"
import documento from "assets/img/icons/common/documento.png"




const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, groupName, theme) {
  return {
    fontWeight:
      groupName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function obterDataFormatada() {
  const data = new Date();
  const ano = data.getFullYear();
  let mes = data.getMonth() + 1; // Adicionamos 1 ao mês pois ele é baseado em zero
  if (mes < 10) {
    mes = '0' + mes; // Adicionamos um zero à esquerda se o mês for menor que 10
  }
  let dia = data.getDate();
  if (dia < 10) {
    dia = '0' + dia; // Adicionamos um zero à esquerda se o dia for menor que 10
  }
  return `${ano}-${mes}-${dia}`;
}

const options = [
  { value: "default", label: "Default" },
  { value: "audio", label: "Audio" },
  { value: "video", label: "Video" },
  { value: "photo", label: "Foto" },
  { value: "document", label: "Documento" },
  { value: "survey", label: "Enquete" },
];

const CreateCampaing = () => {
  const seller_id = localStorage.getItem("seller_id");
  const classes = useStyles();
  const theme = useTheme();
  const [names, setNames] = useState([]);
  const [con_id, setConId] = useState();
  const [groupName, setgroupName] = React.useState([]);
  const [hour_notification, setHourHotification] = useState();
  const [start_day, setStart] = useState(obterDataFormatada());
  const [day, setDay] = useState();
  const [respostas, setRespostas] = useState([
    { id: "resp1", label: 'Resposta', value: "" },
    { id: "resp2", label: 'Resposta', value: "" },
    { id: "resp3", label: 'Resposta', value: "" },
  ]);
  const [fieldType, setFieldType] = useState('message');
  const [contador, setContador] = useState(4);
  const [mark_all, setMarkAll] = useState(false);
  const [image, setImage] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);


  const setGroupNamesApi = (list_groups) => {
    var list_name = []
    list_groups.forEach(function (elemento) {
      list_name.push(elemento.group_name)
    })
    setNames(list_name)
  }


  const handleChange = (event) => {
    setgroupName(event.target.value);
  };


  const startDay = (event) => {
    setStart(event)
    var day = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const [ano, mes, dia] = event.split('-').map(Number);
    const data = new Date(ano, mes - 1, dia);
    setDay(day[data.getDay()])
  }

  const handleSelectChange = (event) => {
    if (event.value === "survey") {
      setFieldType(event.value);
    }
    else {
      setFieldType("message");
    }
    console.log(event.value)
    setSelectedOption(event.value)
  };

  const addField = () => {
    const novoItem = { id: "resp" + contador, label: 'Resposta', value: "" };
    setRespostas(prevLista => [...prevLista, novoItem]);
    setContador(contador + 1)
  }

  const setValueResp = (event) => {
    respostas.forEach(function (elemento) {
      if (elemento.id === event.id) {
        elemento.value = event.value
      }
    })
  };

  const removeResp = (event) => {
    setRespostas(prevLista => prevLista.filter((item, i) => i !== event));
  };


  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const tittle = e.target.elements.input_titulo.value;
    var message = e.target.elements.input_message.value;
    const hour_notification = e.target.elements.hour_notification.value;
    const time_period_notification_start = e.target.elements.start_date.value;

    var link_message = ""
    if (!image) {
      link_message = ""
    } else {
      const S3_BUCKET = process.env.REACT_APP_BUCKET_NAME;
      const REGION = process.env.REACT_APP_S3_REGION;

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_KEY,
      });

      const s3 = new S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: `${seller_id}/${image.name}`,
        Body: image,
        ContentDisposition: 'inline',
        ContentType: image.type,
      };

      try {
        const upload = await s3.putObject(params).promise();
        link_message = `https://imagens-front-end.s3.amazonaws.com/${seller_id}/${image.name}`
      } catch (error) {
        console.error(error);

        alert("Erro ao carregar imagem: " + error.message); // Inform user about the error
      }
    }

    if (selectedOption === "survey") {
      var list_respostas = []
      respostas.forEach(function (elemento) {
        list_respostas.push(elemento.value)
      })

      message = JSON.stringify({
        "message": message,
        "respostas": list_respostas
      })
    }

    const payload = {
      seller_id: seller_id,
      connection_id: con_id,
      tittle: tittle,
      message: message,
      type_message: selectedOption,
      hour_notification: hour_notification,
      days_notification: {
        week_days: [day]
      },
      time_period_notification: {
        period_days: [time_period_notification_start, time_period_notification_start]
      },
      send_to: {
        groups: groupName
      },
      link_message: link_message,
      status: true,
      status_progress: "Created",
      mark_all: mark_all,
      type_of: "single"
    }

    try {
      const res = await ScheduleApi.post(`/schedules-config/`, payload)
      alert("Criado com sucesso!");
    } catch (e) {
      alert(e)
    }

  };


  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await sellersApi.get(`/sellers-groups/?seller_id=${seller_id}`);
        const response_con = await sellersApi.get(`/sellers-connectors/?seller_id=${seller_id}`);
        setGroupNamesApi(response.data);
        setConId(response_con.data[0].id)
        startDay(obterDataFormatada())

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    setImage(e.files[0])

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 400000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 40 MB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    console.log(file.type)
    if (file.type.startsWith("video")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={video} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    if (file.type.startsWith("audio")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={audio} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    if (file.type.startsWith("application")) {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={documento} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    }
    else {
      return (
        <div className="flex align-items-center flex-wrap">
          <div className="flex align-items-center" style={{ width: '40%' }}>
            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
            <span className="flex flex-column text-left ml-3">
              {file.name}
            </span>
          </div>
          <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
          <Bott type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
        </div>
      );
    };
  }


  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-4" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Inserir sua imagem aqui
        </span>
      </div>
    );
  };

  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Criar campanha</h3>
                  </Col>
                  <div className="col text-right">
                    <Link to={`/admin/create-campaign-recurrence`}>
                      <Button
                        color="primary"
                        size="sm"
                      >
                        Criar campanha com Recorrencia, Clique Aqui!
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações da empresa
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-titulo"
                          >
                            Titulo
                          </label>
                          <Input1
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_titulo"
                            placeholder="Titulo"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {fieldType === 'message' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Mensagem
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Mensagem"
                              rows="4"
                              id="input_message"
                              defaultValue=""
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Titulo da enquete
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Titulo da enquete"
                              id="input_message"
                              defaultValue=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <div className="pl-lg-4" id="survey_resp">
                          {respostas.map((id, index) => (
                            <Col md="12">
                              <FormGroup>
                                <Input1
                                  className="form-control-alternative"
                                  placeholder="Resposta"
                                  id={id.id}
                                  defaultValue=""
                                  type="text"
                                  onChange={(e) => setValueResp(e.target)}
                                />
                                <Button id={id.id} className="my-4" color="danger" type="button" onClick={(e) => removeResp(index)}>
                                  Remover Resposta
                                </Button>
                              </FormGroup>
                            </Col>
                          ))}
                        </div>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="button" onClick={addField}>
                          Adicionar mais respostas
                        </Button>
                      </div>
                    )}
                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-type-message"
                        >
                          Tipo de Mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <SelectReact
                            onChange={handleSelectChange}
                            options={options}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">

                        <label
                          className="form-control-label"
                          htmlFor="input-link"
                        >
                          Foto, Audio e Video
                        </label >
                      </Col>
                      <Col md="12">
                        <Card className="shadow">
                          <Toast ref={toast}></Toast>
                          <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                          <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                          <FileUpload ref={fileUploadRef} name="demo[]" maxFileSize={40000000}
                            onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                            headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                            chooseOptions={chooseOptions} cancelOptions={cancelOptions} />
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-time"
                        >
                          Horario do envio da mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="time"
                            id="hour_notification"
                            onChange={ev => setHourHotification(ev.target.value)}
                            step="2"
                            value={hour_notification}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Dia do Envio
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="date"
                            id="start_date"
                            onChange={ev => startDay(ev.target.value)}
                            value={start_day}
                          />
                        </FormGroup>
                      </Col>
                    </Row>


                    <div>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Grupos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="imput_select_groups"
                              multiple
                              value={groupName}
                              onChange={handleChange}
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name} style={getStyles(name, groupName, theme)}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-check"
                          >
                            Marcar todos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label >
                              <input
                                type="checkbox"
                                id="mark_all"
                                checked={mark_all} onChange={() => setMarkAll(!mark_all)}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Criar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default CreateCampaing;
