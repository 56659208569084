import React from 'react';
import * as XLSX from 'xlsx';
import {
    Button,
  } from "reactstrap";

function ExcelGenerator({ data, fileName }) {
  const generateExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button className="my-4" color="primary" type="submit" onClick={generateExcel}>Exportar Relatorio</Button>
  );
}

export default ExcelGenerator;