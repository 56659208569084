import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container
} from "reactstrap";

import { useLocation } from "react-router-dom";
import { useAuth } from "components/AuthContext/AuthProvider";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

const Register = () => {

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const [input, setInputCompany] = useState({
    city: "",
    country: "",
    state: "",
    number: "",
    postal_code: "",
    complement: "",
    street: "",
    name: "",
    phone_number: "",
    cnpj: "",
    password: "",
    email: "",
  });


  const newCompany = useAuth();

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    //if (input.username !== "" && input.password !== "") {
    newCompany.createNewCompany(input);
    //return;
    //}
  };

  const handleInputCompany = (e) => {
    const { name, value } = e.target;
    setInputCompany((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Olá!</h1>
                  <p className="text-lead text-light">
                    Agora você pode criar a sua conta
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="10" md="12">
              <Card className="bg-secondary shadow border-0">
                <CardBody className="px-lg-5 py-lg-5">
                  <Form role="form" onSubmit={handleSubmitEvent}>
                    <h6 className="heading-small text-muted mb-4">
                      Informações da empresa
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Empresa
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="name"
                              name="name"
                              placeholder="Empresa teste"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-address"
                            >
                              Telefone
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="phone_number"
                              name="phone_number"
                              placeholder="(11) 99999-9999"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-city"
                            >
                              CNPJ
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="cnpj"
                              name="cnpj"
                              placeholder="00.000.000/0000-00"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Rua
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="street"
                              name="street"
                              placeholder="Rua"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Cidade
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="city"
                              name="city"
                              placeholder="Cidade"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Estado
                            </label>
                            <Input
                              defaultValue=""
                              className="form-control-alternative"
                              id="state"
                              name="state"
                              placeholder="Estado"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Pais
                            </label>
                            <Input
                              defaultValue=""
                              className="form-control-alternative"
                              id="country"
                              name="country"
                              placeholder="Pais"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Codigo Postal
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="postal_code"
                              name="postal_code"
                              placeholder="00000-000"
                              type="text"
                              onChange={handleInputCompany}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Numero
                            </label>
                            <Input
                              defaultValue=""
                              className="form-control-alternative"
                              id="number"
                              name="number"
                              placeholder="000"
                              type="number"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-country"
                            >
                              Complemento
                            </label>
                            <Input
                              defaultValue=""
                              className="form-control-alternative"
                              id="complement"
                              name="complement"
                              placeholder="Bloco 0 Ap 000"
                              type="text"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">
                      Informações de Acesso
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="inputemail"
                            >
                              Email
                            </label>
                            <Input
                              defaultValue=""
                              className="form-control-alternative"
                              id="email"
                              name="email"
                              placeholder="admin@example.com"
                              type="email"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Password
                            </label>
                            <Input
                              className="form-control-alternative"
                              defaultValue=""
                              id="password"
                              name="password"
                              placeholder="Password"
                              type="password"
                              onChange={handleInputCompany}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    {/* Description */}
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Criar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}

export default Register;