import {
  Card,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";


import { Link, useLocation } from "react-router-dom";


import ScheduleApi from "services/ScheduleApi"
import Header from "components/Headers/Header.js";
import React, { useState, useEffect } from "react";

const Campaigns = () => {
  const seller_id = localStorage.getItem("seller_id");
  const query = new URLSearchParams(useLocation().search);
  var _tittle = query.get("tittle")

  const [seller_campaigns, setSellerCampaigns] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      try {
        var response = null

        if (_tittle != null) {
          response = await ScheduleApi.get(`/schedules-config/?seller_id=${seller_id}&tittle=${_tittle}`);
        }
        else {
          response = await ScheduleApi.get(`/schedules-config/seller/${seller_id}`);
        }
        setSellerCampaigns(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  const handleSubmitDelete = async (event) => {
    const payload = {
      "status_progress":"delete"
    }
    try {
      const res = await ScheduleApi.patch(`/schedules-config/${event}/`, payload)
      alert("Delete em andamento!");
    } catch (e) {
      alert(e)
    }
  };

  const handleSubmitCancel = async (event) => {

    const payload = {
      "status_progress":"canceled"
    }

    try {
      const res = await ScheduleApi.patch(`/schedules-config/${event}/`, payload)
      alert("Cancelamento realizado");
    } catch (e) {
      alert(e)
    }
  };

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Todas as Campanhas</h3>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col">Envio as</th>
                    <th scope="col">Dias</th>
                    <th scope="col">Status</th>
                    <th scope="col">Tipo da Mensagem</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {seller_campaigns.map(seller_campaign => (
                    <tr>
                      <th scope="row">
                        {seller_campaign.tittle}
                      </th>
                      <td>
                        {seller_campaign.hour_notification}
                      </td>
                      <td>
                        {seller_campaign.days_notification.week_days.join(", ")}

                      </td>
                      <td>
                        <span className="mb-0 text-sm">
                          {seller_campaign.status_progress}
                        </span>
                      </td>
                      <td>
                        <span className="mb-0 text-sm">
                          {seller_campaign.type_message}
                        </span>
                      </td>
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href={seller_campaign.id}
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              onClick={(e) => handleSubmitDelete(seller_campaign.id)}
                            >
                              Exluir
                            </DropdownItem>
                            <DropdownItem
                              onClick={(e) => handleSubmitCancel(seller_campaign.id)}
                            >
                              Cancelar
                            </DropdownItem>
                            <Link to={`/admin/alter-campaing?id=${seller_campaign.id}`}>
                              <DropdownItem>
                                Editar
                              </DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
        

      </Container>
    </>
  );
};




export default Campaigns;
