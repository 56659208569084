import {
  Card,
  Button,
  CardHeader,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
} from "reactstrap";

import { Link } from "react-router-dom";

import sellersApi from "services/SellersApi"
import Header from "components/Headers/Header.js";
import React, { useState, useEffect } from "react";

const Groups = () => {

  const [seller_groups, setSellerGroups] = useState([]);
  const seller_id = localStorage.getItem("seller_id");

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const payload = {
      seller_id: seller_id,
    }

    try {
      const res = await sellersApi.post(`/sync-groups/`, payload)
      alert("Sincronização iniciada!");
    } catch (e) {
      alert(e)
    }
  };


  useEffect(() => {
    const fetch = async () => {
      try {

        const response = await sellersApi.get(`/sellers-groups/?seller_id=${seller_id}`);
        setSellerGroups(response.data);

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Grupos</h3>
                  </div>


                  <div className="col text-right">
                    <Button
                      color="primary"
                      href="#pablo"
                      onClick={handleSubmitEvent}
                      size="sm"
                    >
                      Sincronizar Grupos
                    </Button>
                    <Link to={`/admin/create-group`}>
                      <Button
                        color="primary"
                        size="sm"
                      >
                        Criar novo grupo
                      </Button>
                    </Link>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Nome</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {seller_groups.map(seller_groups => (
                    <tr>
                      <th scope="row">
                        <span className="mb-0 text-sm">
                          {seller_groups.group_name}
                        </span>
                      </th>

                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href={seller_groups.id}
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <Link to={`/admin/alter-group?id=${seller_groups.id}`}>
                              <DropdownItem>
                              Ver contatos
                              </DropdownItem>
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Groups;
