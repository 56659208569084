import React, { useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Container
} from "reactstrap";

import { useLocation } from "react-router-dom";
import { useAuth } from "components/AuthContext/AuthProvider";
import Select from "react-select";
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";


const options = [
  { value: "mensal", label: "Mensal" },
  { value: "semestral", label: "Semestral" },
  { value: "anual", label: "Anual" },
];

const optionsMensal = [
  { value: "197", label: "1x R$ 197,00 À vista" },
];

const optionsSemeestral = [
  { value: "1", label: "1x R$ 1.182,00 À vista" },
  { value: "2", label: "2x R$ 591,00" },
  { value: "3", label: "3x R$ 394,00" },
  { value: "4", label: "4x R$ 295,50" },
  { value: "5", label: "5x R$ 236,40" },
  { value: "6", label: "6x R$ 197,00" },
];

const optionsAnual = [
  { value: "1", label: "1x R$ 2.364,00 À vista" },
  { value: "2", label: "2x R$ 1.182,00" },
  { value: "3", label: "3x R$ 788,00" },
  { value: "4", label: "4x R$ 591,00" },
  { value: "5", label: "5x R$ 472,80" },
  { value: "6", label: "6x R$ 394,00" },
  { value: "7", label: "7x R$ 337,72" },
  { value: "8", label: "8x R$ 295,50" },
  { value: "9", label: "9x R$ 262,67" },
  { value: "10", label: "10x R$ 236,40" },
  { value: "11", label: "11x R$ 214,91" },
  { value: "12", label: "12x R$ 197,00" },
];

const Register = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedParcelamentoOption, setSelectedParcelamentoOption] = useState(null);
  const [parcelamentoOpstions, setParcelamentoOpstions] = useState(optionsMensal);
  const [price, setPrice] = useState("R$ 197,00");

  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const [input, setInputCompany] = useState({
    city: "",
    country: "",
    state: "",
    number: "",
    postal_code: "",
    complement: "",
    street: "",
    name: "",
    phone_number: "",
    cnpj: "",
    password: "",
    email: "",
  });

  
  const handleSubmitEvent = (e) => {
    if(e.value == "mensal"){
      setPrice("R$ 197,00")
      setParcelamentoOpstions(null)
      setParcelamentoOpstions(optionsMensal)
    }
    if (e.value == "semestral"){
      setPrice("R$ 1.182,00")
      setParcelamentoOpstions(optionsSemeestral)
    }
    if (e.value == "anual"){
      setPrice("R$ 2.364,00")
      setParcelamentoOpstions(optionsAnual)
    }
    setSelectedOption(e.value)
  }

  const handleInputCompany = (e) => {
    const { name, value } = e.target;
    setInputCompany((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <AuthNavbar />
        <div className="header bg-gradient-info py-7 py-lg-8">
          <Container>
            <div className="header-body text-center mb-7">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <h1 className="text-white">Olá!</h1>
                  <p className="text-lead text-light">
                    Agora você pode criar a sua conta
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-default"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </div>
        <Container className="mt--7" fluid>
          <Row className="justify-content-center">
            <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
              <Card className="card-profile shadow">
                <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="4">
                    <div className="card-profile-image">

                    </div>
                  </Col>
                </Row>

                <CardBody className="pt-0 pt-md-4">
                  <Row>
                    <div className="col">
                      <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                        <div>
                          <span className="heading">5</span>
                          <span className="description">Usuarios</span>
                        </div>
                        <div>
                          <span className="heading">Ilimitado</span>
                          <span className="description">Campanhas</span>
                        </div>
                        <div>
                          <span className="heading">Ilimitado</span>
                          <span className="description">Grupos</span>
                        </div>
                      </div>
                    </div>
                  </Row>
                  <div className="text-center">
                    <h3>
                      Colar info
                      <span className="font-weight-light">, Colar info</span>
                    </h3>
                    <div className="h5 font-weight-300">
                      <i className="ni location_pin mr-2" />
                      Colar info
                    </div>
                    <div className="h5 mt-4">
                      <i className="ni business_briefcase-24 mr-2" />
                      Colar info
                    </div>
                    <div>
                      <i className="ni education_hat mr-2" />
                      Colar info
                    </div>
                    <hr className="my-4" />
                    <p>
                      Colar info
                      Colar info
                      Colar info
                      Colar info
                      Colar info
                      Colar info
                    </p>
                    <hr className="my-4" />
                    <h3>
                      Valor Total:
                      <span className="font-weight-light"> {price}</span>
                    </h3>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className="order-xl-1" xl="5">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">Dados de Pagamento</h3>
                    </Col>

                  </Row>
                </CardHeader>
                <CardBody>
                  <Form>
                    <h6 className="heading-small text-muted mb-4">
                      Dados do Cartão
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              Numero do cartão
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="number_card"
                              name="number_card"
                              placeholder="0000 0000 000 0000"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              Nome do cartão
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="name"
                              name="name"
                              placeholder="João da Silva"
                              type="email"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Validade do cartão
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="00/00"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Codigo de segurança
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-last-name"
                              placeholder="000"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              CPF
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="input-first-name"
                              placeholder="000.000.000-00"
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                      <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-last-name"
                            >
                              Tipo do Plano
                            </label>
                            <br></br>
                            <Select
                              defaultValue={selectedOption}
                              onChange={handleSubmitEvent}
                              options={options}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-first-name"
                            >
                              Condição de pagamento
                            </label>
                            <Select
                              defaultValue={parcelamentoOpstions}
                              onChange={setSelectedParcelamentoOption}
                              options={parcelamentoOpstions}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />
                    <div className="text-center">
                      <Button className="my-4" color="primary" type="submit">
                        Assinar
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
}

export default Register;