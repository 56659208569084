import axios from "axios";

const ScheduleApi = axios.create({
  baseURL: process.env.REACT_APP_SCHEDULE_API,
  headers: {
    'Authorization': process.env.REACT_APP_TOKEN_SCHEDULE,
    'Content-Type':'application/json',
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
}
}); 

export default ScheduleApi;