import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import sellersApi from "services/SellersApi"

const AlterUser = () => {
  const query = new URLSearchParams(useLocation().search);
  var _id = query.get("id")

  const [user, setUser] = useState([]);
  const [password, setPassword] = useState([]);
  const [status, setStatus] = useState();

  const handleStatus = (event) => {
    if (event === true) { setStatus(true) }
    if (event === false) { setStatus(false) }
    else {
      if (status === true) { setStatus(false) }
      else { setStatus(true) }
    }
  }


  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    const user_name = e.target.elements.input_name.value;
    const user_email = e.target.elements.input_email.value;
    const user_username = e.target.elements.input_username.value
    const user_password = e.target.elements.input_password.value;

    const payload_user = {
      username: user_username,
      password: user_password,
      email: user_email,
      name: user_name,
      status: status
    }
    try {
      const res = await sellersApi.patch(`sellers-users/${_id}/`, payload_user)
    } catch (e) {
      alert(e)
    }

    alert("Alterado com sucesso!");
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response_user = await sellersApi.get(`/sellers-users/${_id}`);
        setUser(response_user.data);
        setPassword(response_user.data.password);
        handleStatus(response_user.data.status)

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações de Usuario
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.name}
                            id="input_name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputemail"
                          >
                            Email
                          </label>
                          <Input
                            defaultValue={user.email}
                            className="form-control-alternative"
                            id="input_email"
                            placeholder="admin@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={user.username}
                            id="input_username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={password}
                            id="input_password"
                            placeholder="Password"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-check"
                          >
                            Status
                          </label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label >
                              <input
                                type="checkbox"
                                id="status"
                                checked={status}
                                onChange={handleStatus}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Atualizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AlterUser;
