import Chart from "chart.js";
// react plugin used to create charts
import { Bar } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import {
  chartOptions,
  parseOptions,
  chartExample2,
} from "variables/charts.js";

import Header from "components/Headers/HeaderDash.js";
 
import ScheduleApi from "services/ScheduleApi"
import React, { useState, useEffect } from "react";


const Index = () => {
  const seller_id = localStorage.getItem("seller_id");
  const [seller_campaigns, setSellerCampaigns] = useState([]);
  const [lastValue, setLastValue] = useState([]);


  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }



  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await ScheduleApi.get(`/schedules-config/seller/${seller_id}?status_progress=finished`);
        setSellerCampaigns(response.data);
        
        const response_months = await ScheduleApi.get(`/evens-schedules/last-five-months/${seller_id}/`);
        
        chartExample2.data.labels = response_months.data.list_months

        chartExample2.data.datasets[0].data = response_months.data.list_values

        setLastValue(response_months.data.list_values[response_months.data.list_values.length -1])

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);

  return (
    <>
      <Header data={seller_campaigns} value={lastValue}/>
      {/* Page content */}
      <Container className="mt--7" fluid>
      <Row>
          <Col xl="12">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h6 className="text-uppercase text-muted ls-1 mb-1">
                      Total de mensagens encaminhadas
                    </h6>
                  </div>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Chart */}
                <div className="chart">
                  <Bar
                    data={chartExample2.data}
                    options={chartExample2.options}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Campanhas ativas</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Campanha</th>
                    <th scope="col">Periodo</th>
                    <th scope="col">Hora de envio da mensagem</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {seller_campaigns.map(seller_campaign => (
                    <tr>
                      <th scope="row">{seller_campaign.tittle}</th>
                      <td>{seller_campaign.time_period_notification.period_days.join(", ")}</td>
                      <td>{seller_campaign.hour_notification}</td>
                      <td>{seller_campaign.status_progress}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
