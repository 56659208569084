import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter , Route, Routes, Navigate } from "react-router-dom";

import AuthProvider from "components/AuthContext/AuthProvider";
import PrivateRoute from "components/AuthContext/route";

import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";


import AdminLayout from "layouts/Admin.js";
import Login from "views/pages/Login.js";
import Register from "views/pages/Register.js";
import User from "layouts/User.js";
import Plans from "views/pages/user/Plans.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
  <AuthProvider>
    <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/register" element={<Register />}/>
        <Route element={<PrivateRoute />}>
          <Route path="/plans" element={<Plans />}/>
          <Route path="/admin/*" element={<AdminLayout />} />
          <Route path="/" element={<Navigate to="/admin/index" replace />} />
          <Route path="/user/*" element={<User />} />
        </Route>
      </Routes>
    </AuthProvider>
  </BrowserRouter>
);
