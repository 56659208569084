import {
    Card,
    CardHeader,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Table,
    Container,
    Row,
  } from "reactstrap";
  // core components
  import UserHeader from "components/Headers/UserHeader.js";
  import { useState, useEffect } from "react";
  import sellersApi from "services/SellersApi"
  import { Link } from "react-router-dom";
  
  const ListUsers = () => {
    const seller_id =  localStorage.getItem("seller_id");
  
    const [users, setUser] = useState([]);
        
  
    useEffect(() => {
      const fetch = async () => {
        try {
          const response_user = await sellersApi.get(`/sellers-users/?seller_id=${seller_id}`);
          setUser(response_user.data);
          
        } catch (err) {
          console.error(err);
        }
      };
      fetch();
    }, []);
  
  
    return (
        <>
          <UserHeader />
          {/* Page content */}
          <Container className="mt--7" fluid>
            {/* Table */}
            <Row>
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Todas os Usuarios</h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Nome</th>
                        <th scope="col">Username</th>
                        <th scope="col">Email</th>
                        <th scope="col">Status</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                    { users.map( user => (
                      <tr>
                        <th scope="row">
                            {user.name}
                        </th>
                        <td>
                            {user.username}
                        </td>
                        <td>
                            {user.email}
    
                        </td>
                        <td>
                            {user.status}
                        </td>
                        <td className="text-right">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              href={user.id}
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <Link to={`/user/alter-user?id=${user.id}`}>
                                <DropdownItem>
                                  Editar
                                </DropdownItem>
                              </Link>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    
                      ))} 
                    </tbody>
                  </Table>
                  
                </Card>
              </div>
            </Row>
          </Container>
        </>
      );
  };
  
  export default ListUsers;
  