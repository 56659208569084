import {
  Navbar,
} from "reactstrap";

const AdminNavbar = () => {
  return (
    <>
      <Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">

      </Navbar>
    </>
  );
};

export default AdminNavbar;
