import Connectors from "views/pages/user/Connectors.js";
import CreateUser from "views/pages/user/CreateUser.js";
import ListUsers from "views/pages/user/ListUsers.js";
import Company from "views/pages/user/Company.js";

var routes = [
  {
    path: "/list-users",
    name: "Usuarios",
    icon: "ni ni-circle-08 text-orange",
    component: <ListUsers />,
    layout: "/user",
    hiden:false
  },
  {
    path: "/create-user",
    name: "Criar usuario",
    icon: "ni ni-badge text-orange",
    component: <CreateUser />,
    layout: "/user",
    hiden:false
  },
  {
    path: "/company",
    name: "Dados da empresa",
    icon: "ni ni-shop text-orange",
    component: <Company />,
    layout: "/user",
    hiden:false
  },
  {
    path: "/connectors",
    name: "Connectors",
    icon: "ni ni-planet text-orange",
    component: <Connectors />,
    layout: "/user",
    hiden:false
  },
];
export default routes;
