import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  const loggedInUser = localStorage.getItem("seller_id");
  const plan_status = localStorage.getItem("plan_status");
  if (!loggedInUser) {
    return <Navigate to="/login"/>;
  }
  return <Outlet />;
};

export default PrivateRoute;