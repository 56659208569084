import axios from "axios";

const sellersApi = axios.create({

  baseURL: process.env.REACT_APP_SELLERS_API,
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*",
  },
});

sellersApi.interceptors.request.use(
  config => {
    const token = process.env.REACT_APP_TOKEN_SELLER_API;
    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export default sellersApi;