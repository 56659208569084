import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input as Input1,
  Container,
  Row,
  Col
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserCampaing.js";
import { useState, useEffect } from "react";
import ScheduleApi from "services/ScheduleApi"
import sellersApi from "services/SellersApi";

import React from 'react';
import Input from '@material-ui/core/Input';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



function getStyles(name, groupName, theme) {
  return {
    fontWeight:
      groupName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


const CreateCampaingRec = () => {

  const seller_id = localStorage.getItem("seller_id");
  const [names, setNames] = useState([]);
  const [con_id, setConId] = useState();
  const classes = useStyles();
  const theme = useTheme();
  const [groupName, setgroupName] = React.useState([]);
  const [hour_notification, setHourHotification] = useState();
  const data = new Date();

  const [start_day, setStart] = useState(data.toISOString());

  const [end_day, setEnd] = useState();
  const [checkboxes, setCheckboxes] = useState([
    { id: "Sunday", label: 'Sunday', checked: false },
    { id: "Monday", label: 'Monday', checked: false },
    { id: "Tuesday", label: 'Tuesday', checked: false },
    { id: "Wednesday", label: 'Wednesday', checked: false },
    { id: "Thursday", label: 'Thursday', checked: false },
    { id: "Friday", label: 'Friday', checked: false },
    { id: "Saturday", label: 'Saturday', checked: false },
  ]);
  const [respostas, setRespostas] =  useState([
    { id: "resp1", label: 'Resposta', value:""},
    { id:"resp2", label: 'Resposta', value:""},
    { id: "resp3", label: 'Resposta', value:""},
  ]);
  const [fieldType, setFieldType] = useState('message');
  const [contador, setContador] = useState(4);
  const [mark_all, setMarkAll] = useState(false);
  
  const handleMarkAll = (event) => {
    if (event === true) { setMarkAll(true) }
    if (event === false) { setMarkAll(false) }
    else {
      if (mark_all === true) { setMarkAll(false) }
      else { setMarkAll(true) }
    }
  }

  const setGroupNamesApi = (list_groups) => {
    var list_name = []
    list_groups.forEach(function (elemento) {
      list_name.push(elemento.group_name)
    })
    setNames(list_name)
  }


  const handleChange = (event) => {
    setgroupName(event.target.value);
  };

  const handleCheckboxChange = (id) => {
    setCheckboxes(checkboxes.map(checkbox =>
      checkbox.id === id ? { ...checkbox, checked: !checkbox.checked } : checkbox
    ));
  };

  const startDay = (event) => {
    setStart(event)
  }
  const endDay = (event) => {
    setEnd(event)
  }

  const setHour = (hour) => {
    setHourHotification(hour)
  }
  

  const handleSelectChange = (event) => {
    if (event.target.value === "survey") {
      setFieldType(event.target.value);
    }
    else {
      setFieldType("message");
    }
  };


  // Método para lidar com a mudança de estado do checkbox "marcar todos"
  const handleCheckAllChange = (event) => {
    const checked = event.target.checked;
    setCheckboxes(checkboxes.map(checkbox => ({ ...checkbox, checked })));
  };

  const addField = () => {
    const novoItem = { id: "resp"+contador, label: 'Resposta', value:""};
    setRespostas(prevLista => [...prevLista, novoItem]);
    setContador(contador+1)
  }

  const setValueResp = (event) => {
    respostas.forEach(function (elemento) {
      if (elemento.id===event.id){
        elemento.value = event.value
      }
    })
  };

  const removeResp = (event) => {
    setRespostas(prevLista => prevLista.filter((item, i) => i !== event));
  };
  

  const handleSubmitEvent = async (e) => {
    e.preventDefault();
    const tittle = e.target.elements.input_titulo.value;
    var message = e.target.elements.input_message.value;
    const type_message = e.target.elements.type_message.value;
    const hour_notification = e.target.elements.hour_notification.value;
    const time_period_notification_start = e.target.elements.start_date.value;
    const time_period_notification_end = e.target.elements.end_date.value;
    var link_message = e.target.elements.input_link.value;

    if (link_message === "") {
      link_message = null
    }
    var alter_days = []
    checkboxes.forEach(function (elemento) {
      if (elemento.checked === true) {
        alter_days.push(elemento.id)
      }
    });

    if (type_message ==="survey"){
      var list_respostas = []
      respostas.forEach(function (elemento) {
        list_respostas.push(elemento.value)
      })

      message = JSON.stringify({
        "message": message,
        "respostas": list_respostas
      })
    }

    const payload = {
      seller_id: seller_id,
      connection_id: con_id,
      tittle: tittle,
      message: message,
      type_message: type_message,
      hour_notification: hour_notification,
      days_notification: {
        week_days: alter_days
      },
      time_period_notification: {
        period_days: [time_period_notification_start, time_period_notification_end]
      },
      send_to: {
        groups: groupName
      },
      link_message: link_message,
      status: true,
      status_progress: "Created",
      mark_all:mark_all,
      type_of: "multiple"
    }

    try {
      const res = await ScheduleApi.post(`/schedules-config/`, payload)
      alert("Criado com sucesso!");
    } catch (e) {
      alert(e)
    }

  };
  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await sellersApi.get(`/sellers-groups/?seller_id=${seller_id}`);
        const response_con = await sellersApi.get(`/sellers-connectors/?seller_id=${seller_id}`);
        setGroupNamesApi(response.data);
        setConId(response_con.data[0].id)

      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Criar campanha com recorrência</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações da empresa
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-titulo"
                          >
                            Titulo
                          </label>
                          <Input1
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_titulo"
                            placeholder="Titulo"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {fieldType === 'message' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Mensagem
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Mensagem"
                              rows="4"
                              id="input_message"
                              defaultValue=""
                              type="textarea"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <Row>
                        <Col md="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-message"
                            >
                              Titulo da enquete
                            </label>
                            <Input1
                              className="form-control-alternative"
                              placeholder="Titulo da enquete"
                              id="input_message"
                              defaultValue=""
                              type="text"
                            />
                          </FormGroup>
                        </Col>
                        <div className="pl-lg-4" id="survey_resp">
                        {respostas.map((id, index) => (
                          <Col md="12">
                            <FormGroup>
                              <Input1
                                className="form-control-alternative"
                                placeholder="Resposta"
                                id={id.id}
                                defaultValue=""
                                type="text"
                                onChange={(e) => setValueResp(e.target)} 
                              />
                              <Button id={id.id} className="my-4" color="danger" type="button" onClick={(e) => removeResp(index)}>
                                Remover Resposta
                              </Button>
                            </FormGroup>
                          </Col>
                          ))}
                        </div>
                      </Row>
                    )}
                    {fieldType === 'survey' && (
                      <div className="text-center">
                        <Button className="my-4" color="primary" type="button" onClick={addField}>
                          Adicionar mais respostas
                        </Button>
                      </div>
                    )}


                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-type-message"
                        >
                          Tipo de Mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>
                            <select id="type_message" className="form-control-alternative" onChange={handleSelectChange}>
                              <option value="default">Default</option>
                              <option value="audio">Audio</option>
                              <option value="video">Video</option>
                              <option value="photo">Foto</option>
                              <option value="document">Documento</option>
                              <option value="survey">Enquete</option>
                            </select>
                          </label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">

                        <label
                          className="form-control-label"
                          htmlFor="input-link"
                        >
                          Link da Foto, Audio e Video
                        </label >
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Input1
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_link"
                            placeholder="Link"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">

                        <label
                          className="form-control-label"
                          htmlFor="input-time"
                        >
                          Horario do envio da mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="time"
                            id="hour_notification"
                            onChange={ev => setHour(ev.target.value)}
                            step="600"
                            value={hour_notification}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Periodo
                          </label>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <input
                            type="date"
                            id="start_date"
                            onChange={ev => startDay(ev.target.value)}
                            value={start_day}
                          />
                          <input
                            type="date"
                            id="end_date"
                            onChange={ev => endDay(ev.target.value)}
                            value={end_day}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md="12">
                        <label
                          className="form-control-label"
                          htmlFor="input-check"
                        >
                          Dias para envio da mensagem
                        </label>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label >
                            <input
                              type="checkbox"
                              checked={checkboxes.every(checkbox => checkbox.checked)}
                              onChange={handleCheckAllChange}
                            />
                            Marcar Todos
                          </label>
                          {checkboxes.map(checkbox => (
                            <label>
                              <input
                                type="checkbox"
                                checked={checkbox.checked}
                                onChange={() => handleCheckboxChange(checkbox.id)}
                              />
                              {checkbox.label}
                            </label>
                          ))}
                        </FormGroup>
                      </Col>
                    </Row>
                    <div>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-date"
                          >
                            Grupos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-mutiple-chip-label"
                              id="imput_select_groups"
                              multiple
                              value={groupName}
                              onChange={handleChange}
                              input={<Input id="select-multiple-chip" />}
                              renderValue={(selected) => (
                                <div className={classes.chips}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} className={classes.chip} />
                                  ))}
                                </div>
                              )}
                              MenuProps={MenuProps}
                            >
                              {names.map((name) => (
                                <MenuItem key={name} value={name} style={getStyles(name, groupName, theme)}>
                                  {name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label
                            className="form-control-label"
                            htmlFor="input-check"
                          >
                            Marcar todos
                          </label>
                        </Col>
                        <Col md="12">
                          <FormGroup>
                            <label >
                              <input
                                type="checkbox"
                                id="mark_all"
                                checked={mark_all}
                                onChange={handleMarkAll}
                              />
                            </label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Criar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateCampaingRec;
