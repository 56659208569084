import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import sellersApi from "services/SellersApi"
import bcrypt from 'bcryptjs';

const CreateUser = () => {
  const seller_id = localStorage.getItem("seller_id");


  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    const user_name = e.target.elements.input_name.value;
    const user_email = e.target.elements.input_email.value;
    const user_username = e.target.elements.input_username.value
    const user_password = await bcrypt.hash(e.target.elements.input_password.value, 10);
    const now = new Date();
    const payload_user = {
      username: user_username,
      password: user_password,
      email: user_email,
      name: user_name,
      seller_id:seller_id,
      created_at:now.toISOString()
    }
    try {
      const res = await sellersApi.post(`sellers-users/`, payload_user)
    } catch (e) {
      alert(e)
    }
    alert("Criado com sucesso!");
  };

  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Novo Usuario</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações de Usuario
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Nome
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_name"
                            placeholder="Nome"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="inputemail"
                          >
                            Email
                          </label>
                          <Input
                            defaultValue=""
                            className="form-control-alternative"
                            id="input_email"
                            placeholder="admin@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue=""
                            id="input_password"
                            placeholder="Password"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Atualizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CreateUser;
