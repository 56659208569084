import AlterCampaing from "views/pages/admin/AlterCampaing.js";
import Profile from "views/pages/admin/Profile.js";
import CreateCampaingRec from "views/pages/admin/CreateCampaingRecorrence.js";
import AlterGroup from "views/pages/admin/AlterGroup.js";
import CreateGroup from "views/pages/admin/CreateGroup.js";

var routesHidden = [
  {
    path: "/alter-campaing",
    name: "Alterar Campanha",
    icon: "ni ni-single-02 text-blue",
    component: <AlterCampaing />,
    layout: "/admin",
  },
  {
    path: "/alter-group",
    name: "Alterar Grupo",
    icon: "ni ni-single-02 text-blue",
    component: <AlterGroup />,
    layout: "/admin",
  },
  {
    path: "/create-group",
    name: "Alterar Grupo",
    icon: "ni ni-single-02 text-blue",
    component: <CreateGroup />,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-orange",
    component: <Profile />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/create-campaign-recurrence",
    name: "Criar Campanha Com Recorrencia",
    icon: "ni ni-planet text-orange",
    component: <CreateCampaingRec />,
    layout: "/admin",
    hiden:false
  },

];
export default routesHidden;
