import Index from "views/Index.js";
import Campaigns from "views/pages/admin/Campaigns.js";
import Agenda from "views/pages/admin/Agenda.js";

import CreateCampaing from "views/pages/admin/CreateCampaing.js";
import Contacts from "views/pages/admin/Contacts.js";
import Groups from "views/pages/admin/Groups.js";
import Icons from "views/pages/admin/Icons.js";


var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-chart-bar-32 text-orange",
    component: <Index />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/campaigns",
    name: "Campanhas",
    icon: "ni ni-bullet-list-67 text-orange",
    component: <Campaigns />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/create-campaign-simple",
    name: "Criar Campanha",
    icon: "ni ni-send text-orange",
    component: <CreateCampaing />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/agenda",
    name: "Agenda",
    icon: "ni ni-calendar-grid-58 text-orange",
    component: <Agenda />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/contacts",
    name: "Contatos",
    icon: "ni ni-badge text-orange",
    component: <Contacts />,
    layout: "/admin",
    hiden:false
  },
  {
    path: "/groups",
    name: "Grupos",
    icon: "ni ni-ungroup text-orange",
    component: <Groups />,
    layout: "/admin",
    hiden:false
  },
];
export default routes;
