import { useContext, createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import sellersApi from "services/SellersApi"

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [seller_id, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const loginAction = async (data) => {
    try {
      const res = await sellersApi.post(`seller-auth/`, data)
      if (res.data.username) {
        setUser(res.data.username);
        setToken(res.data.seller_id);
        localStorage.setItem("seller_id", res.data.seller_id);
        localStorage.setItem("username", res.data.username);
        localStorage.setItem("plan_status", res.data.plan_status);
        
        navigate("/");  
        //if (res.data.plan_status){
        //  navigate("/");
        //  return;
        //}
        //else{
        //  navigate("/plans");
        //}
      }
      throw new Error(res.message);
    } catch (err) {
      console.error(err);
    }
    return false
  };
  const createNewCompany = async (data) => {
    try {
      const res = await sellersApi.post(`sellers/`, data)

      setUser(res.data.username);
      setToken(res.data.seller_id);
      localStorage.setItem("seller_id", res.data.seller_id);
      localStorage.setItem("username", res.data.username);

      navigate("/plans");
      console.log(res.status)
      
    } catch (err) {
      var erros = JSON.stringify(err.response.data)
      erros = erros.replace("{", "").replace("}", "").replace("[","").replace("]","")
      alert(erros)
    }
    return false
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("seller_id");
    localStorage.removeItem("username");
    navigate("/login");
  };

  return (
    <AuthContext.Provider value={{ seller_id, user, loginAction, logOut, createNewCompany }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};