import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import UserHeader from "components/Headers/UserHeader.js";
import { useState, useEffect } from "react";
import sellersApi from "services/SellersApi"

const Company = () => {
  const seller_id = localStorage.getItem("seller_id");

  const [seller, setSeller] = useState([]);


  const handleSubmitEvent = async (e) => {
    e.preventDefault();

    const seller_name = e.target.elements.input_seller.value;
    const seller_phone = e.target.elements.input_telefone.value;
    const seller_cnpj = e.target.elements.input_cnpj.value;
    const seller_rua = e.target.elements.input_rua.value;
    const seller_cidade = e.target.elements.input_cidade.value;
    const seller_estado = e.target.elements.input_estado.value;
    const seller_pais = e.target.elements.input_pais.value;
    const seller_codigo_postal = e.target.elements.input_codigo_postal.value;
    const seller_numero = e.target.elements.input_numero.value;
    const seller_complemento = e.target.elements.input_complemento.value;

    const payload_seller = {
      city: seller_cidade,
      country: seller_pais,
      state: seller_estado,
      number: seller_numero,
      postal_code: seller_codigo_postal,
      complement: seller_complemento,
      street: seller_rua,
      name: seller_name,
      phone_number: seller_phone,
      cnpj: seller_cnpj
    }

    try {
      const res = await sellersApi.patch(`sellers/${seller.id}/`, payload_seller)

    } catch (e) {
      alert(e)
    }
    alert("Alterado com sucesso!");
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const response = await sellersApi.get(`/sellers/${seller_id}`);
        setSeller(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  return (
    <>
      <UserHeader />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

          </Col>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Minha conta</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form role="form" onSubmit={handleSubmitEvent}>
                  <h6 className="heading-small text-muted mb-4">
                    Informações da empresa
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Empresa
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.name}
                            id="input_seller"
                            placeholder="Empresa teste"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Telefone
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.phone_number}
                            id="input_telefone"
                            placeholder="(11) 99999-9999"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            CNPJ
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.cnpj}
                            id="input_cnpj"
                            placeholder="00.000.000/0000-00"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>

                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Rua
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.street}
                            id="input_rua"
                            placeholder="Rua"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>

                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Cidade
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.city}
                            id="input_cidade"
                            placeholder="Cidade"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Estado
                          </label>
                          <Input
                            defaultValue={seller.state}
                            className="form-control-alternative"
                            id="input_estado"
                            placeholder="Estado"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Pais
                          </label>
                          <Input
                            defaultValue={seller.country}
                            className="form-control-alternative"
                            id="input_pais"
                            placeholder="Pais"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Codigo Postal
                          </label>
                          <Input
                            className="form-control-alternative"
                            defaultValue={seller.postal_code}
                            id="input_codigo_postal"
                            placeholder="00000-000"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Numero
                          </label>
                          <Input
                            defaultValue={seller.number}
                            className="form-control-alternative"
                            id="input_numero"
                            placeholder="000"
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Complemento
                          </label>
                          <Input
                            defaultValue={seller.complement}
                            className="form-control-alternative"
                            id="input_complemento"
                            placeholder="Bloco 0 Ap 000"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  {/* Description */}

                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      Atualizar
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Company;
