import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";


import Header from "components/Headers/Header.js";
import ScheduleApi from "services/ScheduleApi"
import { useState, useEffect } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import { DayPicker } from "react-day-picker";
import { formatDate } from '@fullcalendar/core'

import 'react-day-picker/dist/style.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';

const Agenda = () => {

  const localizer = momentLocalizer(moment);
  const [events, setEvents] = useState([]);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [days, setDays] = useState(new Date().getDay());

  const createEvents = (values) => {
    var list_events = []
    values.forEach(function (elemento) {
      var data = new Date(elemento.date_send_message)
      list_events.push({
        title: elemento.message.slice(0, 15),
        start: data,
        end: data.setMinutes(data.getMinutes() + 10),
      })
    })
    setEvents(list_events)
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const seller_id = localStorage.getItem("seller_id");
        const response = await ScheduleApi.get(`/evens-schedules/two-months/${seller_id}`);
        createEvents(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetch();
  }, []);


  const handleDayClick = (day, { selected }) => {
    setSelectedDay(formatDate(day));
    setYear(formatDate(day).split("/")[2]);
    setMonth(formatDate(day).split("/")[0]);
    setDays(formatDate(day).split("/")[1]);
  };


  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
        <Col md="12">
            <Card className="shadow">
              <div className="py-3 d-flex">
                <div className="flex-sm-column">
                  <DayPicker selectedDays={selectedDay} onDayClick={handleDayClick} />
                </div>
                <div className="flex-sm-column">
                  <Calendar
                    selectable
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    onSelectEvent={(event) => alert(event.title)}
                    defaultView={Views.WEEK}
                    defaultDate={new Date(year, month, days)}
                    style={{ height: 700 }}
                  />
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Agenda;
