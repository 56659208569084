import AlterUser from "views/pages/user/AlterUser.js";

var routesHidden = [
  {
    path: "/alter-user",
    name: "User Profile",
    icon: "ni ni-single-02 text-orange",
    component: <AlterUser />,
    layout: "/admin",
    hiden:false
  }
];
export default routesHidden;
